@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.ant-form-item {
	margin-bottom: 1.25rem !important;
}

.ant-form-item-label {
	padding: 0 0 .25rem !important;
	text-align: start !important;
}

.ant-form-item-label label {
	font-family: "DM Sans" !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 24px !important;
}

.ant-select-status-warning .ant-select-selector {
	border-color: #44BA41 !important;
}

.ant-select-selector {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
}

.select-normal {
	border: 1px solid #212121 !important;
}

.ant-select {
	/* height: 52px !important; */
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
}

.ant-select-disabled span {
	color: var(--Black-Default, #000) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 100% !important;
	/* 1rem */
}

.select-borderless .ant-select-selector {
	height: 32px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 0px !important;
}

.select-borderless .ant-select {
	height: 32px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 0px !important;
}

.select-dotted .ant-select-selector {
	height: 2rem !important;
	width: 100% !important;
	border-radius: 1.875rem !important;
	border: 1px dashed var(--FMW-Primary, #0170BD) !important;
}

.select-dotted {
	height: 2rem !important;
	width: 100% !important;
	border-radius: 1.875rem !important;
}

.select-border-blue {
	width: 100% !important;
	height: 3.25rem !important;
	flex-shrink: 0 !important;
	border-radius: 0.1875rem !important;
	background-color: transparent !important;
}

.select-border-blue .ant-select-selector {
	width: 100% !important;
	height: 3.25rem !important;
	flex-shrink: 0 !important;
	border-radius: 0.1875rem !important;
	border: 1px solid var(--FMW-Primary, #0170BD) !important;
	background-color: transparent !important;
}

.select-status {
	width: 5.8125rem !important;
	/* width: auto; */
	height: 2rem !important;
	flex-shrink: 0;
}

.select-status .ant-select-selector {
	height: 2rem !important;
	width: 5.8125rem !important;
	/* width: auto; */
	border-radius: 1.5625rem !important;
}


.ant-form-item-extra {
	color: #388E3C !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	font-family: "DM Sans" !important;
	line-height: 20px !important;
}

.ant-select .ant-select-arrow {
	color: #212121 !important;
	font-size: 16px !important;
}

.ant-input::placeholder {
	color: var(--text-primary, rgba(0, 0, 0, 0.87));
	opacity: 0.2;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.00938rem;
}

.border-blue::placeholder {
	color: var(--FMW-Primary, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 140% !important;
	opacity: 0.5 !important;
	margin-top: .88rem !important;
}

/* .input-borderless:focus {
	max-width: 100%;
	width: 100%;
	height: auto;
	align-items: center;
	border: 1px solid #d9d9d9 !important;
	background: transparent !important;
	outline: 1px solid #d9d9d9 !important;
	outline-offset: 0px !important;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: none !important;
	--tw-ring-color: none !important;
	--tw-ring-offset-shadow: 0px !important;
	--tw-ring-shadow: 0px !important;
	box-shadow: none !important;
	border-color: transparent !important;
} */

.input_borderless {
	border-radius: 0px;
	width: 100%;
	height: auto !important;
	border: none !important;
	font-size: 18px;
	background: transparent !important;
	padding: 0px !important;
}

.ant-input-affix-wrapper:hover {
	border: none !important;
}

.input_borderless:hover {
	border: none !important;
	box-shadow: none !important;
	outline: 0 !important;
}

.input_borderless:focus {
	border: none !important;
	box-shadow: none !important;
}

.input_borderless:focus-within {
	border: none !important;
	box-shadow: none !important;
}

.ant-input-affix-wrapper:focus {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-affix-wrapper-focused {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

/* .ant-input-affix-wrapper:hover {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
} */

.ant-tabs-nav-wrap {
	padding: 0px 0px !important;
	background-color: inherit !important;
}

.ant-tabs-nav-list {
	/* width: 30%; */
	display: flex;
	justify-content: space-between;
}

.ant-tabs-ink-bar {
	display: none !important;
}

.ant-breadcrumb-separator {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-breadcrumb a {
	color: var(--blue-grey, #545D69) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	/* 150% */
}

.ant-breadcrumb span {
	color: var(--FMW-Primary, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	text-transform: capitalize !important;
}

.ant-tabs-tab-active {
	display: flex !important;
	padding: 1rem 0.75rem !important;
	align-items: flex-start !important;
	gap: 0.5rem !important;
	border: 1px solid var(--Border-secondary, #ECECEC) !important;
	background: #FFF !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-tabs-tab:hover>.ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #0170BD) !important;
}

.ant-tabs-tab:focus>.ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #0170BD) !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
	border: none !important;
}

.underlined_tab .ant-tabs-tab-active {
	display: flex !important;
	padding: .75rem !important;
	align-items: center !important;
	justify-content: center !important;
	border: none !important;
	border-bottom: .1875rem solid #0170BD !important;
	background: transparent !important;
}

.underlined_tab .ant-tabs-tab {
	width: 50% !important;
	display: flex !important;
	padding: .75rem !important;
	align-items: center !important;
	justify-content: center !important;
	background: transparent !important;
}

.underlined_tab .ant-tabs-nav-list {
	width: 100% !important;
}

.underlined_tab .ant-tabs-tab-btn {
	color: var(--text-black, #222) !important;
	font-family: "DM Sans" !important;
	font-size: 1.125rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 150% !important;
}

.ant-table-wrapper {
	border-radius: 0.5rem !important;
	background: var(--White, #FFF) !important;
	box-shadow: 0px 2px 4px 0px rgba(34, 34, 34, 0.12) !important;
}

.ant-table-thead .ant-table-cell {
	background: #F4FCFF !important;
	color: var(--text-black, #222) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
	text-transform: capitalize !important;
}

.ant-table-cell {
	color: var(--Gray-900, #101828) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

td:first-child {
	color: var(--Gray-900, #101828) !important;
	font-family: "Lexend" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-tag {
	display: flex;
	width: 8.4375rem;
	padding: 0.5rem .75rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 1.25rem;
	/* border: 2px solid var(--Primary-Alert, #FFB201); */
}

.ant-spin-dot-item {
	background-color: #FFF !important;
}

.ant-modal-header {
	display: none !important;
}

.ant-modal-close-x {
	display: none !important;
}

.ant-modal-content {
	border-radius: 0.875rem !important;
	background: #FFF !important;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.ant-switch {
	width: 3rem !important;
	height: 1.6rem !important;
	border: 2px solid #fff !important;
}

.ant-switch-checked {
	background: #fff !important;
	border: 4px solid #388E3C !important;
}

.ant-switch-checked>.ant-switch-handle::before {
	background-color: #388E3C !important;
}

.ant-switch-checked .ant-switch-handle {
	top: 0.2px !important;
}

.ant-dropdown-menu {
	padding: 2.19rem 1.81rem !important;
	background-color: #FFF !important;
}

.ant-dropdown-menu-item {
	padding: 0px !important;
	margin: 5px !important;
	margin-bottom: 1.88rem !important;
}

.ant-dropdown-menu-title-content {
	color: var(--FMW-Primary, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 140% !important;
	padding: 5px !important;
}

.ant-menu {
	background: transparent !important;
	display: flex !important;
	flex-direction: column !important;
	row-gap: 1.56rem !important;
	border-inline-end: 0px !important;
}

.ant-menu-item {
	padding-left: 3.19rem !important;
	height: 2.8125rem !important;
	border-radius: 0px !important;
}

.ant-menu-title-content {
	color: var(--Blue-grey, #7E93AE) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	margin-inline-start: .75rem !important;
}

.ant-menu-item-icon>svg {
	fill: #7E93AE !important;
}

.ant-menu-item-icon {
	font-size: 1.5rem !important;
}

.ant-badge-count {
	background: #3F51F4 !important;
}

.ant-menu-item-selected {
	background: #E8FAFF !important;
	border-left: 0.4375rem solid #0170BD !important;
	border-radius: 0px !important;
}

.ant-menu-item-selected>.ant-menu-title-content {
	color: var(--FMW-Primary, #0873B9) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	margin-inline-start: .75rem !important;
}

.ant-menu-item-selected .ant-menu-item-icon>path {
	fill: #0873B9 !important;
}

.ant-menu-submenu-arrow {
	display: none !important;
}

.ant-menu-submenu-title {
	border-top: .1rem solid rgba(207, 240, 252, 0.50) !important;
	border-bottom: .1rem solid rgba(207, 240, 252, 0.50) !important;
	height: 2.75rem !important;
}

.ant-menu-submenu-title .ant-menu-title-content {
	color: var(--FMW-Primary, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 1.3125rem !important;
	letter-spacing: 0.02188rem !important;
}

.ant-steps-item {
	position: relative;
	display: flex !important;
	justify-content: space-between;
	vertical-align: top;
	align-items: center;
	margin-bottom: 28px;
}

.ant-steps-item-icon {
	width: 40px !important;
	height: 40px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.ant-steps-item-icon {
	background-color: #226cbf !important;
	border-color: #fff !important;
}

.ant-steps-icon {
	color: #fff !important;
	font-size: 15px !important;
}

.ant-steps-item-title {
	color: #6fa1f0 !important;
}

.ant-steps-item-active .ant-steps-item-icon {
	background-color: #fff !important;
	border-color: #226cbf !important;
}

.ant-steps-item-active .ant-steps-icon {
	color: #0071bc !important;
	font-size: 15px !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	background-color: #6fa1f0 !important;
	border-color: #6fa1f0 !important;
}

/* .ant-steps-item-finish .ant-steps-item-title {
	color: #fff !important;
} */

.ant-steps-item-tail::after {
	width: 2px !important;
	height: 45px !important;
	background-color: white !important;
	margin-left: 3px !important;
}

.project-detail {
	display: flex !important;
	justify-content: center !important;
	align-items: flex-start !important;
}

.project-detail>.ant-steps-item-active .ant-steps-item-icon {
	background-color: #D6EFFF !important;
	border: 3px solid #0170BD !important;
}

.project-detail>.ant-steps-item-active .ant-steps-item-title {
	color: #0170BD !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 150% !important;
	letter-spacing: -0.011rem !important;
	margin-top: .3rem !important;
}

.project-detail .ant-steps-item-container {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}

.ant-descriptions-title {
	color: var(--Gray-900, #101828) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 1.5rem !important;
}

.ant-descriptions-item-label {
	min-width: 15.5rem;
	color: var(--Black, #000) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}

.ant-descriptions-item-content {
	color: var(--Gray-500, #667085) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}

.ant-radio>span {
	color: var(--Gray-700, #344054) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.25rem !important;
	/* 142.857% */
}

.ant-radio-inner {
	background-color: #fff !important;
	width: 1.5rem !important;
	height: 1.5rem !important;
}

.ant-radio-inner::after {
	background-color: #0170BD !important;
	transform: scale(.8) !important;
}

.ant-radio-wrapper-disabled span {
	color: var(--text-black, #222) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 150% !important;
	/* 1.5rem */
	letter-spacing: -0.022rem !important;
}

.reject .ant-radio-inner::after {
	background-color: #C04544 !important;
}

.reject .ant-radio-inner {
	background-color: #fff !important;
	border: 1px solid #C04544 !important;
}

.collapse-white {
	background-color: #fff !important;
}

.collapse-white .ant-collapse-header {
	background-color: #fff !important;
}

.collapse-white .ant-collapse-content {
	border-top: 0px !important;
}

.ant-collapse {
	border-radius: 0.5rem !important;
	border: 1.2px solid var(--FMW-Primary, #0170BD) !important;
	background-color: transparent !important;
}

.ant-collapse-header {
	padding: 2rem !important;
}

.ant-collapse-content-box {
	padding: 0px !important;
}

.ant-btn-primary {
	background-color: #0170BD !important;
}

.ant-form-item-row {
	display: flex;
	flex-direction: column !important;
}

.ant-checkbox-inner {
	width: 1.5625rem !important;
	height: 1.5625rem !important;
	flex-shrink: 0 !important;
	border-radius: 0.0625rem !important;
	border: 1px solid var(--FMW-Primary, #0170BD) !important;
	background: var(--White, #FFF) !important;
}

.ant-checkbox-inner::after {
	box-sizing: border-box !important;
	position: absolute !important;
	top: 50% !important;
	inset-inline-start: 21.5% !important;
	display: table !important;
	width: 5.7142857142857135px !important;
	height: 12.142857px !important;
	border-top: 0 !important;
	border-inline-start: 0 !important;
	content: "" !important;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s !important;
	border-color: #0170BD !important;
}

.ant-checkbox-wrapper-disabled span {
	color: var(--text-black, #222) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 150% !important;
	/* 1.5rem */
	letter-spacing: -0.022rem !important;
}   

.border-blue {
	width: 100% !important;
	min-height: 5.125rem !important;
	height: auto !important;
	flex-shrink: 0;
	border-radius: 0.125rem !important;
	border: 1px solid var(--FMW-Primary, #0170BD) !important;
	background-color: transparent !important;
}

.ant-input-number-handler-wrap {
	display: none !important;
}