.mitigation-title.JoyTypography-root {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #222;
    font-family: DM Sans;

}


.fmw-input-joy-mitigation.JoyInput-root {
    border: none ;
    --Input-focusedHighlight: none;
    --Input-minHeight: 1.5rem;

}

.fmw-input-joy-mitigation.JoyInput-root:hover:not(.Joy-focused) {
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
  }