.fmw-input-search-joy.JoyInput-root {
    border-radius: 2px;
    margin-bottom: 8px;
    background-color: inherit;
    border: 1px solid var(--color-light-blue);
    --Input-focusedHighlight: var(--color-light-blue);
    height:56px;
}

.fmw-input-search-joy.JoyInput-root:hover:not(.Joy-focused) {
    border: 1px solid var(--color-light-blue);
}


.reddit-input-search.MuiTextField-root .MuiFilledInput-root {
    border: 1px solid var(--color-light-blue) !important;
    border-left: 3px solid var(--color-info) !important;
    height:56px !important;
    border-radius: 2px;
}

.reddit-input-search .MuiFormLabel-root {
    font-size: 16px !important;
    font-weight: 500;
}

.reddit-input-search .MuiFormLabel-root.Mui-focused {
    color: #000000 !important;
}
 