.signin-title {
    font-size: 16.75px !important;
    margin-bottom: 3px !important;
}

.signin-h {
    width: 30px !important;
    background: var(--color-info) !important;
    margin-bottom: 1.25rem !important;
}

.signin-tagline {
    color: #646464 !important;
    width: 21rem !important;
    margin-bottom: 1.35rem !important;
}

.forgot-tagline {
    color: #646464 !important;
    width: 100%;
    margin-bottom: 1.35rem !important;
}

.reddit-input-error {
    border-color: 1px solid red !important;
    outline: none !important;
}


.reddit-input-error.MuiTextField-root .MuiFilledInput-root {
    border: 1px solid red !important;
    border-left: 3px solid red !important;
}
.reddit-input.MuiTextField-root .MuiFilledInput-root {
    border: 1px solid var(--color-primary-light) !important;
    border-left: 3px solid var(--color-info) !important;
}



.reddit-input .MuiFormLabel-root {
    font-size: 15px !important;
}

.reddit-input .MuiFormLabel-root.Mui-focused {
    color: #646464 !important;
}

.signin-dialog .fmw-checkbox-label .JoyCheckbox-checkbox {
    border-radius: 0;
}

.signin-dialog .fmw-checkbox-label .JoyCheckbox-label {
    color: #484848 !important;
    font-size: 15px !important;
}

.sign-btn {
    width: 100%;
    font-size: 15px !important;
    border-radius: 0px !important;
    min-height: 2.35rem !important;
  }

  .signup-btn {
    min-width: 100px;
    font-size: 15px !important;
    border-radius: 0px !important;
    color: #646464 !important;
    background-color: #fff !important;
    border: 1px solid #646464 !important;
    min-height: 2.35rem !important;
  }