@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
	--color-primary: #00baba;
	--color-info: #00c3e6;
	--bg-primary: #e6fafd;
	--bg-info: #00c3e6;
	--color-primary-light: #26deff67;
	--bg-primary-light: #26deff67;
	--p-color: #646464;
	font-size: 14.5px !important;
	--bg-primary-grdient: linear-gradient(
		90deg,
		rgb(38, 222, 255) 0%,
		rgb(0, 186, 186) 35%
	);
	--color-danger: #d93025;
	--color-technical: #0873b9;
	--color-background-technical: #f8fdff;
	--color-light-blue: #cff0fc;
	--color-blue-grey: #7e93ae;
	--color-active-stepper: #d6efff;
	--color-border-table: #e8f8fe;
}


.fmw-font.JoyTypography-root {
	font-family: 'DM Sans', sans-serif !important;
	color: red !important;
}

body {
	margin: 0;
	font-family: 'DM Sans', sans-serif !important;
	font-family: 'Roboto ', sans-serif !important;
	/* font-family: 'Gorditas', cursive;
  font-family: 'Roboto ', sans-serif !important;
  font-family: 'Gorditas', cursive;
font-family: 'Nunito', sans-serif;
font-family: 'Roboto', sans-serif; */
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

select.business-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select.business-select:focus {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 5px;
	border-radius: none;
}

option.business-option:focus {
	outline: none !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 2px;
	border-radius: none;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
}

.highlighter {
	width: 63px;
	height: 4px;
	background: var(--color-primary) 0% 0% no-repeat padding-box;
	border-radius: 40px;
	opacity: 1;
	margin-bottom: 8px !important;
}

.meta-title {
	color: var(--color-primary) !important;
	font-size: 16px !important;
	font-weight: 700;
}

.main-title {
	color: var(--color-info) !important;
	font-size: 38px !important;
	font-weight: 700;
}

.custom-stepper .MuiStep-root {
	padding-left: 0;
	padding-right: 16px;
}

.custom-stpper-tab.MuiStepper-root {
	padding: 0px 0px 0px 10px;
}

.custom-stepper .MuiStepConnector-root,
.custom-stepper .MuiStepLabel-iconContainer {
	display: none;
}

.custom-stepper .stepper-btn {
	padding: 8px 16px 16px 16px;
}

.custom-stepper .sb-active {
	background-color: var(--bg-primary);
	height: 70px;
}

.MuiStepLabel-label .step-label {
	font-size: 14px;
}

.MuiStepLabel-label .step-description {
	font-size: 17px;
	color: var(--color-info) !important;
	font-weight: 700;
}

.MuiStepLabel-label.Mui-active .step-label,
.MuiStepLabel-label.Mui-completed .step-label {
	color: var(--color-primary) !important;
}

.MuiStepLabel-label.Mui-active .step-description,
.MuiStepLabel-label.Mui-completed .step-description {
	color: var(--color-primary) !important;
}

.next-btn {
	min-width: 184px;
	font-size: 15px !important;
	font-weight: 600 !important;
	border-radius: 2px !important;
}

.generate-btn {
	min-width: 184px;
	font-size: 15px !important;
	font-weight: 600 !important;
	border-radius: 5px !important;
}

.next-btn.apply-btn {
	width: 230px !important;
}

.fmw-input .JoyInput-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-primary-light);
	--Input-focusedHighlight: var(--color-primary-light);
}

.fmw-input .JoyInput-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-primary-light);
}

.fmw-input-joy.JoyInput-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-primary-light);
	--Input-focusedHighlight: var(--color-primary-light);
	--Input-decorator-childHeight: min(4.375rem, var(--Input-minHeight));
}

.fmw-input-joy.JoyInput-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-primary-light);
}

.fmw-input-joy-search.JoyInput-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-light-blue);
	--Input-focusedHighlight: var(--color-light-blue);
	--Input-decorator-childHeight: min(4.375rem, var(--Input-minHeight));
}

.fmw-input-joy-search.JoyInput-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-light-blue);
}

.fmw-input-joy-technical.JoyInput-root {
	border-radius: 18px;
	margin-bottom: 8px;
	color: #222 !important;
	font-size: '18px';
	font-weight: 500;
	background-color: inherit;
	border: 1px solid var(--color-light-blue) !important;
	--Input-focusedHighlight: var(--color-light-blue);
	--Input-decorator-childHeight: min(4.375rem, var(--Input-minHeight));
}

.fmw-input-joy-technical.JoyInput-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-light-blue);
}

.fmw-input-joy-comment.JoyTextarea-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-light-blue);
	--Textarea-focusedHighlight: var(--color-light-blue);
	--Input-decorator-childHeight: min(4.375rem, var(--Input-minHeight));
}

.fmw-input-joy-comment.JoyTextarea-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-light-blue);
}

.fmw-input-joy-comment-committed.JoyTextarea-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-technical);
	--Textarea-focusedHighlight: var(--color-technical);
	--Input-decorator-childHeight: min(4.375rem, var(--Input-minHeight));
	--Textarea-placeholderColor: var(--color-technical);
}

.fmw-input-joy-comment-committed.JoyTextarea-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-technical);
}

.fmw-input-joy-score.JoyInput-root {
	border-radius: 2px;
	margin-bottom: 8px;
	margin-top: 5px;
	width: 68px;
	background-color: inherit;
	border-left: none;
	border-top: none;
	border-right: none;
	border-bottom: 1px solid var(--color-primary-light);
	--Input-focusedHighlight: none;
	--Input-minHeight: 0rem;
}

.fmw-input-joy-score.JoyInput-root:hover:not(.Joy-focused) {
	border-bottom: 1px solid var(--color-primary-light);
	border-left: none;
	border-top: none;
	border-right: none;
}

.fmw-select.JoySelect-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-primary-light);
	--Select-focusedHighlight: var(--color-primary-light);
}

.fmw-select.JoySelect-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-primary-light);
}

.fmw-select-search.JoySelect-root {
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: inherit;
	border: 1px solid var(--color-light-blue);
	--Select-focusedHighlight: var(--color-light-blue);
}

.fmw-select-search.JoySelect-root:hover:not(.Joy-focused) {
	border: 1px solid var(--color-light-blue);
}

.fmw-textarea.JoyTextarea-root {
	border-radius: 2px !important;
	margin-bottom: 8px !important;
	background-color: inherit !important;
	border: 1px solid var(--color-primary-light) !important;
	--Textarea-focusedHighlight: var(--color-primary-light);
}

.fmw-code-chip {
	border-radius: 2px !important;
	color: #646464 !important;
	background-color: var(--bg-primary) !important;
	cursor: pointer;
}

.fmw-checkbox-label {
	font-size: 16px !important;
	color: #2c2c2c !important;
	font-weight: 500 !important;
	font-family: Roboto, sans-serif !important;
}

.fmw-checkbox-label-committed {
	font-size: 16px !important;
	color: #2c2c2c !important;
	font-weight: 550 !important;
	font-family: Roboto, sans-serif !important;
}

.fmw-checkbox-label-error {
	font-size: 16px !important;
	color: var(--color-danger) !important;
	font-weight: 500 !important;
	font-family: Roboto, sans-serif !important;
}

.fmw-checkbox-label .star {
	color: #d10000;
}

.fmw-checkbox-label .JoyCheckbox-label {
	font-size: 1rem !important;
	color: #2c2c2c !important;
	font-weight: 500 !important;
	font-family: Roboto, sans-serif !important;
}

.fmw-checkbox-label .JoyCheckbox-checkbox {
	color: var(--color-primary);
	border: 1px solid #a0a0a0;
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 10%,
		rgba(230, 250, 253, 1) 100%
	);
}

.fmw-radio-label .JoyRadio-icon {
	color: var(--color-primary) !important;
}

.fmw-radio-label .JoyRadio-radio {
	color: var(--color-primary) !important;
	border: 1px solid #a0a0a0 !important;
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 10%,
		rgba(230, 250, 253, 1) 100%
	) !important;
}

.fmw-divider {
	width: 100%;
	height: 2px;
	background: var(--color-info) 0% 0% no-repeat padding-box;
}

.fmw-link {
	color: var(--color-primary) !important;
}

/* register partner */
.fmw-checkbox-sub-label .upload-sub-label {
	font-size: 14px;
	color: #a0a0a0 !important;
}

.upload-sub-label-error {
	margin-top: 2px !important;
	margin-bottom: 2px !important;
	display: flex !important;
	align-items: center !important;
	font-size: 14px;
	color: var(--color-danger) !important;
}

.fmw-upload-typography.JoyTypography-root {
	margin-bottom: -5px !important;
}

.fmw-coreindicators-sub-label {
	font-size: 14px;
	color: #2c2c2c !important;
	margin-bottom: 2px !important;
}

.fmw-coreindicators-text {
	color: '#484848' !important;
	letter-spacing: 0px;
	font-size: 14px;
}

/* register partner */

.fmw-page-title {
	color: var(--color-primary) !important;
	font-size: 20px !important;
	font-weight: 700;
}

.fmw-onboard-partner-label {
	font-size: 16px;
	color: #0069df !important;
	font-weight: 700;
}

.fmw-onboard-partner-sub-label {
	color: #646464 !important;
	font-weight: 400;
	font-family: Roboto, sans-serif !important;
	font-size: 16px !important;
}

.fmw-onboard-partner-sub-label-error {
	color: var(--color-danger) !important;
	font-weight: 400;
	font-family: Roboto, sans-serif !important;
	font-size: 16px !important;
}

.fmw-radio-partner .JoyRadio-radio {
	color: var(--color-primary);
	border: 1px solid #a0a0a0;
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 10%,
		rgba(230, 250, 253, 1) 100%
	);
}

.date:hover {
	border: 1px solid var(--color-primary-light);
}

.fmw-date-picker .MuiInputBase-root {
	border-radius: 2px !important;
	height: 47px !important;
}

.fmw-date-picker .MuiOutlinedInput-notchedOutline {
	border-color: var(--color-primary-light) !important;
}

.fmw-step4 .JoyInput-input {
	height: 46px !important;
}

.fmw-p {
	color: var(--p-color) !important;
	font-size: 0.95rem !important;
}

.text-blue {
	color: #0069df !important;
}

/* .fmw-select {
  
} */

.fmw-btn-noBg {
	background-color: transparent !important;
	color: #0069df;
	border: none !important;
	outline: none !important;
}

.fmw-btn-no-hover:hover {
	background-color: transparent !important;
	border: none !important;
	outline: none !important;
}

.fmw-btn-primary {
	background-color: var(--color-primary) !important;
	border-radius: 2px !important;
}

.fmw-btn-primary:hover {
	background-color: #029999 !important;
}

.fmw-btn-info {
	background-color: var(--color-info) !important;
	border-radius: 7px !important;
	width: 140px;
	height: 38px;
}

.fmw-btn-primary:hover {
	background-color: #029999 !important;
}

.fmw-btn-secondary {
	background-color: #0069df !important;
	border-radius: 2px !important;
	width: 230px !important;
	height: 38px !important;
	--Icon-fontSize: 1.5rem !important;
	font-weight: 600 !important;
}

.fmw-btn-secondary-auto {
	background-color: #0069df !important;
	border-radius: 2px !important;
	height: 38px !important;
	--Icon-fontSize: 1.5rem !important;
	font-weight: 600 !important;
	width: 139px;
}

.fmw-btn-secondary-neutral {
	background-color: inherit !important;
	border-radius: 2px !important;
	height: 38px !important;
	--Icon-fontSize: 1.5rem !important;
	font-weight: 600 !important;
	width: 139px;
	color: #646464 !important;
	border: 1px solid #646464 !important;
}

.file-chip-primary {
	background-color: #1976d212 !important;
	border: none !important;
	color: #1976d2d4 !important;
}

.file-stepper-primary {
	background-color: #1976d212 !important;
	border: none !important;
	color: #1976d2d4 !important;
	cursor: pointer !important;
}

.file-chip-danger {
	background-color: #d32f2f12 !important;
	border: none !important;
	color: #d32f2fd9 !important;
}

.file-chip-primary svg {
	transform: rotate(46deg) !important;
}

.fmw-page-account-title {
	color: #202020 !important;
	font-size: 0.925rem !important;
	font-weight: '600';
}

.page-account-sub-title {
	font-size: 0.925rem;
	color: #202020 !important;
}

.fmw-phone.form-control {
	background-color: inherit !important;
	border-radius: 2px !important;
	border: 1px solid var(--color-primary-light) !important;
	--Input-focusedHighlight: var(--color-primary-light);
	margin-bottom: 8px;
	height: 47px !important;
	width: 100% !important;
}

.fmw-phone-error.form-control {
	background-color: inherit !important;
	border-radius: 2px !important;
	border: 1px solid var(--color-danger) !important;
	--Input-focusedHighlight: var(--color-danger);
	margin-bottom: 8px;
	height: 47px !important;
	width: 100% !important;
}

.react-tel-input {
	--Input-focusedHighlight: var(--color-primary-light);
}

.flags.react-tel-input .flag-dropdown {
	border: 1px solid var(--color-primary-light) !important;
	--Input-focusedHighlight: var(--color-primary-light);
}

.flags-error.react-tel-input .flag-dropdown {
	border: 1px solid var(--color-danger) !important;
	--Input-focusedHighlight: var(--color-danger);
}

.fmw-onboard-partner-sub-label-modal {
	color: inherit !important;
	font-weight: 400 !important;
	font-size: 14px !important;
}

.css-19skn5s-JoyTypography-root {
	font-family: 'Roboto ', sans-serif !important;
}

.btn-fmw {
	width: 140px;
	font-weight: 600 !important;
	height: 38px;
}

.acc-title-fmw {
	font-weight: 700 !important;
}

.divider.MuiDivider-root {
	border-style: solid;
	border-color: #000000 !important;
	border-bottom-width: 0;
	height: 100%;
	border-right-width: medium;
	height: 20px;
}

.divider-sub.MuiDivider-root {
	border-style: solid;
	border-color: #000000 !important;
	border-bottom-width: 0;
	height: 100%;
	border-right-width: medium;
	height: 25px;
}

.fmw-pagination.MuiTableCell-root {
	border-bottom: none !important;
}

.fmw-technical-label-committed {
	font-size: 22px !important;
	color: var(--color-technical) !important;
	font-weight: 500 !important;
	/* font-family: Roboto, sans-serif !important; */
}

.fmw-accrdion.MuiPaper-root {
	border: solid 1px var(--color-light-blue);
	border-radius: 8px !important;
	margin-bottom: 0rem !important;
	padding: 15px;
}

.fmw-accrdion-table.MuiPaper-root {
	border: solid 1px var(--color-light-blue);
	border-radius: 8px !important;
	margin-bottom: 0rem !important;
	padding-top: 15px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
}

.fmw-accrdion-svg.MuiSvgIcon-root {
	font-size: 32px;
}

.fmw-accrdion-drag-svg.MuiSvgIcon-root {
	color: var(--color-technical);
	font-size: 20px;
}

.accordion-title.JoyTypography-root {
	font-size: 18px !important;
	font-weight: 500 !important;
	color: #222;
	display: flex;
	align-items: center;
}

.fmw-accrd.css-1udso76-MuiPaper-root-MuiAccordion-root:before {
	height: 0px !important;
}

.custom-stepper-screening .MuiStepLabel-label.Mui-active {
	color: var(--color-technical);
	font-weight: 500;
	font-size: 16px;
}

.custom-stepper-screening .MuiStepLabel-label.Mui-completed {
	color: var(--color-technical);
	font-weight: 500;
	font-size: 16px;
}

.custom-stepper-screening .MuiStepLabel-label {
	font-weight: 500;
	font-size: 14px;
}

.basic .MuiTableCell-root {
	border-left: 2px solid var(--color-light-blue);
	border-bottom: none;
	border-top: 2px solid var(--color-light-blue) !important;
	font-size: 16px;
	font-weight: 400;
	padding: 6px 18px;
	font-family: 'DM Sans', sans-serif !important;
}

.basic-comment .MuiTableCell-root {
	border-left: 2px solid var(--color-light-blue);
	border-bottom: 2px solid var(--color-light-blue);
	border-top: 2px solid var(--color-light-blue) !important;
	font-size: 16px;
	font-weight: 400;
}

.basic-first .MuiTableCell-root {
	border-left: none !important;
}

.table .MuiTableCell-root.MuiTableCell-head {
	color: #222;
	font-size: 14px;
	text-transform: none;
	font-weight: 500;
	/* font-family: DM Sans; */
}

.basic-intro .MuiTableCell-root {
	font-size: 16px;
	font-weight: 400;
}

.intro-tables.MuiTable-root {
	border: 1px solid rgba(224, 224, 224, 1);
	border-radius: 8px;
	background: #fff;
	box-shadow: 0px 2px 4px 0px rgba(34, 34, 34, 0.12);
}

/* .css-heg063-MuiTabs-flexContainer{
    justify-content: space-between;
} */

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  background-color: #F4FCFF !important;
}

.fmw-input-search-joy.JoyInput-root {
  border-radius: 2px;
  margin-bottom: 8px;
  background-color: inherit;
  border: 1px solid var(--color-light-blue);
  --Input-focusedHighlight: var(--color-light-blue);
  height:56px;
}

.fmw-input-search-joy.JoyInput-root:hover:not(.Joy-focused) {
  border: 1px solid var(--color-light-blue);
}


.reddit-input-search.MuiTextField-root .MuiFilledInput-root {
  border: 1px solid var(--color-light-blue) !important;
  border-left: 3px solid var(--color-info) !important;
  height:56px !important;
  border-radius: 2px;
}

.reddit-input-search .MuiFormLabel-root {
  font-size: 16px !important;
  font-weight: 500;
}

.reddit-input-search .MuiFormLabel-root.Mui-focused {
  color: #000000 !important;
}

.active .css-nhf46j-MuiButtonBase-root-MuiListItemButton-root{
	background-color: #E8FAFF !important;
	border-left: 5px solid #0873B9 !important;
}

.active .css-8wwhzc-MuiButtonBase-root-MuiListItemButton-root{
	background-color: #E8FAFF !important;
	border-left: 5px solid #0873B9 !important;
}

.active span{
	color: #0873B9 !important;
}


.ant-steps-item {
	position: relative;
	display: flex !important;
	justify-content: flex-start;
	vertical-align: top;
	align-items: center;
	margin-bottom: 28px;
}

.ant-steps-item-icon {
	width: 40px !important;
	height: 40px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.ant-steps-item-icon {
	background-color: #226cbf !important;
	border-color: #fff !important;
}

.ant-steps-icon {
	color: #fff !important;
	font-size: 15px !important;
}

.ant-steps-item-title {
	color: #6fa1f0 !important;
}

.ant-steps-item-active .ant-steps-item-icon {
	background-color: #fff !important;
	border-color: #226cbf !important;
}

.ant-steps-item-active .ant-steps-icon {
	color: #0071bc !important;
	font-size: 15px !important;
}

.ant-steps-item-active .ant-steps-item-title {
	color: #fff !important;
}