.committed-menu .MuiMenu-list {
    color: var(--color-technical);
    display: flex;
    flex-direction: column;
    gap: 10px;
    display: inline-flex;
    padding: 35px 73px 46px 29px !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}