@tailwind base;
@tailwind components;
@tailwind utilities;

/* .bgImg {
	background: url('./assets/bgImg.png');
	background-repeat: no-repeat;
	height: 460px;
    width: 100%;
	background-size: contain;
} */

.bglbr8shd {
	border-radius: 8px;
	background: #f0f5ff;
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.signup-info {
	color: var(--White, #fff);
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 26.4px */
}

.signup-info-text {
	color: #c6e1ff;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 18.2px */
}

.signup-start {
	color: #171a1f;
	text-align: center;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 44px; /* 157.143% */
}

.signup-start-text {
	color: #565e6c;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

.signup-begin {
	color: #171a1f;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 22.4px */
}

.signup-begin-text {
	color: #171a1f;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

.nxt-btn {
	display: flex;
	width: 172px;
	height: 52px;
	flex-direction: column;
	flex-shrink: 0;
	display: flex;
	padding: 10px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
	border-radius: 25px;
	background: #226cbf;
	color: var(--White, var(--White, #fff));
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	margin-top: 54.5px;
}

.already {
	color: #596e8f;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
}

.already-text {
	color: #226cbf;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	text-decoration-line: underline;
}

.ant-steps-item {
	position: relative;
	display: flex !important;
	justify-content: flex-start;
	vertical-align: top;
	align-items: center;
	margin-bottom: 28px;
}

.ant-steps-item-icon {
	width: 40px !important;
	height: 40px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.ant-steps-item-icon {
	background-color: #226cbf !important;
	border-color: #fff !important;
}

.ant-steps-icon {
	color: #fff !important;
	font-size: 15px !important;
}

.ant-steps-item-title {
	color: #6fa1f0 !important;
}

.ant-steps-item-active .ant-steps-item-icon {
	background-color: #fff !important;
	border-color: #226cbf !important;
}

.ant-steps-item-active .ant-steps-icon {
	color: #0071bc !important;
	font-size: 15px !important;
}

.ant-steps-item-active .ant-steps-item-title {
	color: #fff !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	background-color: #6fa1f0 !important;
}

.ant-steps-item-finish .ant-steps-item-title {
	color: #fff !important;
}

.ant-steps-item-tail::after {
	width: 2px !important;
	height: 45px !important;
	background-color: white !important;
	margin-left: 3px !important;
}

.ant-form-item-extra {
	color: #565e6c !important;
	font-size: 13px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 140% !important; /* 18.2px */
	margin-left: 8px;
}
/* 
.ant-form-item-control-input > div{
    display: none !important;
} */
/* 
.ant-form-item-explain{
    display: none !important;
} */

.ant-input-number-group-addon {
	border: none !important;
	background-color: transparent !important;
}

.reg-phone {
	display: flex;
	width: 100%;
	height: 52px;
	/* padding: 0px 0px 0px 19px; */
	align-items: center;
	border-radius: 8px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-phone:hover {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-phone:focus {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-input {
	/* display: flex; */
	width: 100%;
	height: 52px;
	/* padding: 0px 0px 0px 19px; */
	align-items: center;
	border-radius: 8px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-input:hover {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-input:focus {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.ant-upload-drag {
	display: flex;
	width: 100%;
	height: 52px !important;
	padding: 0px 12px 0px 12px;
	align-items: center;
	border-radius: 8px !important;
	border: 1px solid #9095a0 !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.ant-upload-text {
	color: var(--Field-text, #565e6c) !important;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 22px !important; /* 157.143% */
}

.ant-select-selector {
	border: none !important;
}

.ant-select-selection-placeholder {
	color: var(--Field-text, #565e6c) !important;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 22px !important; /* 157.143% */
}

.select-info {
	color: var(--gray-700, #344054);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 142.857% */
}

.home-header {
	color: var(--Gray-1, #333);
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 21px; /* 75% */
}

.home-header-text {
	color: var(--Gray-1, #333);
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */
}

.review {
	color: var(--gray-800, #1d2939);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 155.556% */
}

.review-text {
	color: var(--gray-400, #98a2b3);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 155.556% */
}
