.signin-title {
    font-size: 16.75px !important;
    margin-bottom: 3px !important;
    font-weight: 600;
}

.signin-h {
    width: 30px !important;
    background: var(--color-info) !important;
    margin-bottom: 1.25rem !important;
}

.signin-tagline {
    color: #646464 !important;
    width: 17rem !important;
    margin-bottom: 1.35rem !important;
}

.verify-signin-tagline {
    color: #646464 !important;
    /* width: 22rem !important; */
    margin-bottom: 1.35rem !important;
}


/* .reddit-input {

} */

.reddit-input .MuiFormLabel-root {
    font-size: 15px !important;
}

.reddit-input .MuiFormLabel-root.Mui-focused {
    color: #646464 !important;
}

.signin-dialog .fmw-checkbox-label .JoyCheckbox-checkbox {
    border-radius: 0;
}

.signin-dialog .fmw-checkbox-label .JoyCheckbox-label {
    color: #484848 !important;
    font-size: 15px !important;
}

.sign-btn {
    min-width: 100px;
    font-size: 15px !important;
    border-radius: 0px !important;
    min-height: 2.35rem !important;
}

.signup-btn {
    min-width: 100px;
    font-size: 15px !important;
    border-radius: 0px !important;
    color: #646464 !important;
    background-color: #fff !important;
    border: 1px solid #646464 !important;
    min-height: 2.35rem !important;
}

.fmw-verify-input .JoyInput-root {
    border-radius: 2px;
    margin-bottom: 8px;
    background-color: inherit;
    border: 1px solid var(--color-primary-light);
    --Input-focusedHighlight: var(--color-primary-light);
    height: 3.753rem !important;
    border-left: 3px solid var(--color-info) !important;
}

.fmw-verify-input .JoyInput-root:hover:not(.Joy-focused) {
    border: 1px solid var(--color-primary-light);

}